<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @hidden="hideModal()"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon :icon="taskTypeIcon"></b-icon>
        <span>Crea {{ taskTypeLabel }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="
          cancel();
          cancelEventCreation();
        "
      >
        &times;
      </button>
    </template>
    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn
          @click="
            cancel();
            cancelEventCreation();
          "
          size="sm"
          variant="outline-secondary"
          >Annulla</b-btn
        >
        <b-button
          @click="handleSubmit(createEvent)"
          type="button"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
        >
          Salva
        </b-button>
      </b-form-group>
    </template> -->
    <validation-observer ref="observer2" v-slot="{ handleSubmit, invalid }">
      <b-card :data-type="taskType">
        <b-card-text>
          <div data-field="title">
            <base-input
              vid="title"
              :name="beFormCreate.title.label"
              :label="beFormCreate.title.label"
              v-model="inputVal.title"
              :placeholder="beFormCreate.title.label"
              :rules="getRules('title', 'task')"
              maxlength="25"
            />
          </div>

          <div data-field="description">
            <base-textarea
              vid="description"
              :name="beFormCreate.description.label"
              :label="beFormCreate.description.label"
              v-model="inputVal.description"
              :rules="getRules('description', 'task')"
              :placeholder="beFormCreate.description.label"
            />
          </div>

          <!-- <b-button-group
            v-b-toggle:collapse-2
            class="my-2 filter-button-group"
            v-if="fromCalendar"
          >
            <span class="mr-2">Appuntamento</span>
            <span class="when-open">
              <b-icon icon="chevron-up"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="chevron-down"></b-icon>
            </span>
          </b-button-group> -->

          <!-- <b-collapse class="pb-2" id="collapse-2" :visible="fromCalendar"> -->
          <div v-if="fromCalendar" data-field="all_day">
            <base-checkbox
              vid="allDay"
              name="allDay"
              label="Tutto il giorno"
              groupLabel=""
              v-model="inputVal.allDay"
              v-if="inputVal.fromDate === inputVal.toDate"
            />
          </div>

          <!-- <div v-if="fromCalendar"> -->
          <!-- <div class="row"> -->
          <!-- <div class="col-md-6"> -->
          <div data-field="start_time" v-if="fromCalendar">
            <base-timepicker
              v-if="!inputVal.allDay"
              vid="startTime"
              name="startTime"
              label="Dalle ore"
              v-model="inputVal.startTime"
              :rules="{ required: taskType == 0 }"
            />
          </div>
          <!-- <div class="col-md-6"> -->
          <div data-field="end_time" v-if="fromCalendar">
            <base-timepicker
              v-if="!inputVal.allDay"
              vid="endTime"
              name="endTime"
              label="Alle ore"
              v-model="inputVal.endTime"
              :rules="{ required: taskType == 0 }"
            />
          </div>
          <!-- </div> -->
          <!-- </div> -->

          <!-- <div class="row"> -->
          <div v-if="taskType !== 2" data-field="started_at">
            <base-datepicker
              vid="started_at"
              name="started_at"
              :label="beFormCreate.start.label"
              v-model="inputVal.fromDate"
              :rules="{ required: taskType !== 2 }"
            />
            <!-- :rules="getRules('started_at', 'task')" -->
          </div>
          <div data-field="ended_at" v-show="fromCalendar">
            <base-datepicker
              vid="ended_at"
              name="ended_at"
              :label="beFormCreate.end.label"
              v-model="inputVal.toDate"
              :rules="{ required: taskType == 0 }"
            />
            <!-- :rules="getRules('ended_at', 'task')" -->
          </div>
          <!-- </div> -->
          <!-- </b-collapse> -->

          <!-- -->
          <div class="row" v-if="taskType !== 2" data-field="recurrence">
            <div class="col-md-6">
              <base-select
                :name="beFormCreate.recurrence_type.label"
                :label="beFormCreate.recurrence_type.label"
                v-model="inputVal.recurrence_type"
                :options="beFormCreate.recurrence_type.options"
              />
              <!-- :rules="getRules('started_at', 'task')" -->
            </div>
            <div class="col-md-6" data-type>
              <base-select
                name="Termina"
                label="Termina"
                v-model="inputVal.frequencyEnd"
                :options="endFrequencyOptions"
                :rules="getCustomRules('recurrence_type')"
              />
            </div>
            <div class="col-md-12" v-if="inputVal.frequencyEnd === 'after'">
              <base-input
                name="Numero di occorrenze"
                label="Numero di occorrenze"
                type="number"
                v-model="inputVal.toDateFrequency"
                :rules="getCustomRules('frequencyEndAfter')"
              />
              <!-- <small
                class="number-to-dates"
                v-if="inputVal.toDateFrequency"
                v-html="convertNumberToDates"
              ></small> -->
            </div>
            <div class="col-md-12" v-if="inputVal.frequencyEnd === 'until'">
              <base-datepicker
                vid="frequency_end_date"
                name="frequency_end_date"
                label="Data di termine"
                v-model="inputVal.recurrence_end_date"
                :rules="getCustomRules('frequencyEndUntil')"
                :min="inputVal.fromDate"
              />
            </div>
          </div>
          <!-- -->
          <!-- -->
          <div class="row" v-if="taskType !== 2" data-field="memo">
            <div class="col-md-6">
              <base-select
                name="memo"
                label="Promemoria"
                v-model="inputVal.memo"
                :options="[{ value: 'Y', text: 'Sì' }]"
              />
            </div>
            <div class="col-md-6" v-if="inputVal.memo === 'Y'">
              <base-input
                name="Giorni di visualizzazione promemoria"
                label="Giorni di visualizzazione promemoria"
                type="number"
                v-model="inputVal.memo_days"
                min="1"
                max="999"
                step="1"
                :rules="getCustomRules('memo_days')"
              />
              <small class="days-to-months" v-if="inputVal.memo_days">{{
                convertDaysToMonths
              }}</small>
            </div>
          </div>

          <div v-if="!fromCalendar && taskType === 1" data-field="expired_at">
            <base-datepicker
              vid="expired_at"
              :name="beFormCreate.expired_at.label"
              :label="beFormCreate.expired_at.label"
              v-model="inputVal.expired_at"
              :rules="getRules('expired_at', 'task')"
            />
          </div>

          <!-- Se è una nota attiva la possibilità di gestire l'ereditarietà -->
          <div class="row" v-if="taskType === 2 && entity === policy_type">
            <div class="col-md-12">
              <base-select
                :name="beFormCreate.inheritance.label"
                vid="inheritance"
                :label="beFormCreate.inheritance.label"
                v-model="inheritance"
                :options="beFormCreate.inheritance.options"
                :rules="{ required: true }"
              />
            </div>
          </div>

          <div data-field="is_public">
            <base-radio
              vid="is_public"
              :name="beFormCreate.is_public.label"
              :label="beFormCreate.is_public.label"
              v-model="is_public"
              :options="beFormCreate.is_public.options"
              :rules="getRules('is_public', 'task')"
              @select="onChangeIsPublic"
            />

            <base-select
              v-if="is_public === 'N'"
              vid="users"
              :name="beFormCreate.users.label"
              :label="beFormCreate.users.label"
              v-model="inputVal.users"
              :options="usersOptions"
              :multiple="true"
              :taggable="false"
              :closeOnSelect="false"
              :rules="getRules('users', 'task')"
            />
          </div>

          <div v-if="taskType === 1" data-field="status_task">
            <base-select
              vid="status_task"
              :name="beFormCreate.status_task.label"
              :label="beFormCreate.status_task.label"
              v-model="inputVal.status_task"
              :options="beFormCreate.status_task.options"
              :rules="{ required: true }"
            />
          </div>
          <!-- Se è una nota attiva la possibilità di creare un appuntamento -->
          <div class="row" v-if="taskType === 2">
            <div class="col-md-12">
              <base-checkbox
                v-if="taskType === 2"
                vid="create_appointment"
                name="create_appointment"
                groupLabel=""
                label="Aggiungi Appuntamento"
                v-model="create_appointment"
                @change="checkAppointment"
              />
            </div>
          </div>
          <div style="visibility: hidden; height: 1px">
            <base-label
              vid="task_type"
              name="task_type"
              :value="inputVal.task_type"
            />
          </div>
        </b-card-text>
        <b-button
          @click="handleSubmit(createEvent)"
          type="button"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
          class="float-right"
        >
          Salva
        </b-button>
      </b-card>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseTimepicker from "@/components/form/BaseTimepicker";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseRadio from "@/components/form/BaseRadio";
import BaseLabel from "@/components/form/BaseLabel";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin],
  data() {
    return {
      usersOptions: null,
      create_appointment: false,
      is_public: "Y",
      policy_type: "policy",
      inheritance: 2,
      numberOfOccurrences: null,
      frequencyEndDate: null,
      endFrequencyOptions: [
        // { value: "never", text: "Mai" },
        { value: "after", text: `Dopo "n" occorrenze` },
        { value: "until", text: "Fino al giorno" },
      ],
    };
  },
  props: {
    // taskType: {
    //   type: Number,
    //   default: 0,
    // },
    entity: {
      type: String,
      default() {
        return "";
      },
    },
    id: {
      type: String,
      default: "eventCreateModal",
    },
    value: null,
    beRulesCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    beFormCreate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    BaseSelect,
    BaseTimepicker,
    BaseDatepicker,
    BaseRadio,
    BaseLabel,
  },
  computed: {
    // convertNumberToDates() {
    //   if (this.inputVal.recurrence_type == null) return;
    //   let res = "";
    //   res = [...Array(+this.inputVal.toDateFrequency)]
    //     .map((el, index) => {
    //       const i = index + 1;
    //       return `<li>${this.convertSingleDate(i)}</li>`;
    //     })
    //     .join("");
    //   return `<ol>${res}</ol>`;
    // },
    convertDaysToMonths() {
      return this.inputVal.memo_days && this.inputVal.memo_days > 31
        ? this.convertDaysToYearsMonthsDays
        : null;
    },
    convertDaysToYearsMonthsDays() {
      let days = this.inputVal.memo_days;
      const years = Math.floor(days / 365);
      days %= 365;

      const months = Math.floor(days / 30);
      days %= 30;

      const result = [];
      if (years) result.push(`${years} ann${years > 1 ? "i" : "o"}`);
      if (months) result.push(`${months} mes${months > 1 ? "i" : "e"}`);
      if (days) result.push(`${days} giorn${days > 1 ? "i" : "o"}`);

      return result.join(", ");
    },
    fromCalendar() {
      return this.taskType === 0 ? true : false;
    },
    taskType() {
      console.debug("eventCreate taskType(): ", this.inputVal.task_type);
      return this.inputVal.task_type;
    },
    taskTypeIcon() {
      let icon;
      switch (this.taskType) {
        case 0:
          icon = "calendar2-event";
          break;
        case 1:
          icon = "paperclip";
          break;
        case 2:
          icon = "journal-minus";
          break;
        default:
          icon = "question-circle";
      }
      return icon;
    },
    taskTypeLabel() {
      let label;
      switch (this.taskType) {
        case 0:
          label = "Appuntamento";
          break;
        case 1:
          label = "Attività";
          break;
        case 2:
          label = "Nota";
          break;
        default:
          label = "sconosciuto";
      }
      return label;
    },
    inputVal: {
      get() {
        // return {...this.value, is_public: 'Y'};
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    moment,
    /* convertSingleDate(frequency) {
      let ret = "-";
      let n = frequency;
      switch (this.inputVal.recurrence_type) {
        case 0:
          // Giornaliera
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "d")
            .format("DD/MM/YYYY");
          break;
        case 7:
          // Settimanale
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "w")
            .format("DD/MM/YYYY");
          break;
        case 1:
          // Mensile
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "M")
            .format("DD/MM/YYYY");
          break;
        case 2:
          // Bimestrale
          n = n * 2;
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "M")
            .format("DD/MM/YYYY");
          break;
        case 3:
          // Trimestrale
          n = n * 3;
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "M")
            .format("DD/MM/YYYY");
          break;
        case 4:
          // Quadrimestrale
          n = n * 4;
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "M")
            .format("DD/MM/YYYY");
          break;
        case 6:
          // Semestrale
          n = n * 6;
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "M")
            .format("DD/MM/YYYY");
          break;
        case 12:
          // Annuale
          ret = this.moment(this.inputVal.fromDate)
            .add(n, "y")
            .format("DD/MM/YYYY");
          break;
        default:
          console.log("No date_frequency matched!");
          break;
      }
      return ret;
    }, */
    getCustomRules(field) {
      let rules = {};
      if (field === "recurrence_type") {
        if (this.inputVal.recurrence_type) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "frequencyEndAfter") {
        if (this.inputVal.frequencyEnd === "after") {
          rules.between = { min: "1", max: "365" };
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "frequencyEndUntil") {
        if (this.inputVal.frequencyEnd === "until") {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "memo_days") {
        rules.between = { min: "1", max: "999" };
        if (this.inputVal.memo === "Y") {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      }
      return rules;
    },
    hideModal() {
      console.log("hideModal");
      this.$bvModal.hide(this.id);
      this.is_public = "Y";
      this.inputVal.is_public = null;
      this.inheritance = 0;
      this.inputVal.inheritance = 0;
    },
    onChangeIsPublic(checkedOption) {
      if (checkedOption === "Y") {
        this.inputVal.users = [];
      }
    },
    cancelEventCreation() {
      this.$emit("cancel");
    },
    checkAppointment() {
      console.log("Change", this.create_appointment);
      this.$emit("addAppointment", this.create_appointment);
    },
    createEvent() {
      this.inputVal.is_public = this.is_public;
      this.inputVal.inheritance = this.inheritance;
      this.$emit("create");
    },
    ...mapGetters("auth", ["user"]),
  },
  beforeMount() {
    this.beRules["task"] = this.beRulesCreate;
    const owner = this.user();
    this.usersOptions = this.beFormCreate.users.options.filter(
      (user) => user.value !== owner.id
    );
  },
};
</script>
<style lang="scss" scoped>
:deep(.collapse > span:last-of-type > div.form-group) {
  margin-bottom: 0;
}
:deep(.collapse + span) {
  display: block;
  margin-top: 1rem;
}
:deep(.collapse .form-group) {
  margin-bottom: 1rem;
}
:deep(.days-to-months) {
  position: absolute;
  left: 15px;
  bottom: -4px;
}
:deep(.number-to-dates) {
  display: block;
}
:deep(.number-to-dates ol) {
  padding: 0 0 0 15px;
  columns: 5;
}
:deep(.number-to-dates ol > li::marker) {
  font-weight: bold;
}
:deep(#eventCreateModal .multiselect) {
  height: 35px;
}
:deep(#eventCreateModal [data-type="0"] .card-text),
:deep(#eventCreateModal [data-type="1"] .card-text) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}
/* Layout Appuntamenti */
:deep(#eventCreateModal [data-type="0"] [data-field="title"]) {
  grid-area: 1 / 1 / 2 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="description"]) {
  grid-area: 2 / 1 / 3 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="all_day"]) {
  grid-area: 3 / 1 / 4 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="start_time"]) {
  grid-area: 4 / 1 / 5 / 2;
}
:deep(#eventCreateModal [data-type="0"] [data-field="end_time"]) {
  grid-area: 4 / 2 / 5 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="started_at"]) {
  grid-area: 5 / 1 / 6 / 2;
}
:deep(#eventCreateModal [data-type="0"] [data-field="ended_at"]) {
  grid-area: 5 / 2 / 6 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="recurrence"]) {
  grid-area: 6 / 1 / 7 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="memo"]) {
  grid-area: 7 / 1 / 8 / 3;
}
:deep(#eventCreateModal [data-type="0"] [data-field="is_public"]) {
  grid-area: 8 / 1 / 9 / 3;
}
/* Layout Appuntamenti */
/* Layout Attività */
:deep(#eventCreateModal [data-type="1"] [data-field="title"]) {
  grid-area: 1 / 1 / 2 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="description"]) {
  grid-area: 2 / 1 / 3 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="recurrence"]) {
  grid-area: 4 / 1 / 5 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="started_at"]) {
  grid-area: 3 / 1 / 4 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="memo"]) {
  grid-area: 5 / 1 / 6 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="status_task"]) {
  grid-area: 7 / 1 / 8 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="expired_at"]) {
  grid-area: 8 / 1 / 9 / 3;
}
:deep(#eventCreateModal [data-type="1"] [data-field="is_public"]) {
  grid-area: 9 / 1 / 10 / 3;
}
/* Layout Attività */
</style>
