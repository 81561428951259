import Repository from "@/repositories/Repository";

// Verb 	    URI 	                Action 	  Route Name
// GET 	      /photos 	            index 	  photos.index
// GET 	      /photos/create 	      create 	  photos.create
// POST 	    /photos 	            store 	  photos.store
// GET 	      /photos/{photo} 	    show 	    photos.show
// GET 	      /photos/{photo}/edit 	edit 	    photos.edit
// PUT/PATCH 	/photos/{photo} 	    update 	  photos.update
// DELETE 	  /photos/{photo} 	    destroy 	photos.destroy

const resource = "/import_logs";
export default {
  index(queryString) {
    // get the list
    return queryString
      ? Repository.get(`${resource}?${queryString}`)
      : Repository.get(`${resource}`);
  },
  index_legacy(queryString) {
    // get the list of legacy
    return queryString
      ? Repository.get(`${resource}/legacy?${queryString}`)
      : Repository.get(`${resource}/legacy`);
  },
};
